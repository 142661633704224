import React, {useState} from 'react';
import {View, Text, StyleSheet, Pressable} from "react-native-web";
import '../stylesheets/CreatorBizSignUp.css';
import ProfileSettings from "./ProfileSettings";
import Campaign from "./Campaign";
import {getCreatorCampaigns} from "../services/CreatorService";
import {Image} from "rebass";
import profileSelected from "../icons/DupeIcons-21-Profile-Selected.png";
import profileUnselected from "../icons/DupeIcons-20-Profile-Unselected.png";
import campaignSelected from "../icons/DupeIcons-19-Campaign-Selected.png";
import campaignUnselected from "../icons/DupeIcons-18-Campaign-Unselected.png";
import {Link} from "react-router-dom";

const CreatorBizSignUp = () => {
    const [leftMenuSelection, setLeftMenuSelection] = useState('creatorProfile');
    const [campaignTabSelected, setCampaignTabSelected] = useState('activeCampaigns');
    const [acceptedCampaigns, setAcceptedCampaigns] = useState([]);
    const [invitedCampaigns, setInvitedCampaigns] = useState([]);
    const [publicCampaigns, setPublicCampaigns] = useState([]);
    const dupeBizAccessIds = ["41f659a0-2768-4bb0-aba8-cb2e5dcad6bb", "aa5f1f61-022d-4469-89f2-ce5bf1049e86", "8b6ab865-0465-484f-be11-9ed04c6032e8", "8b6ab865-0465-484f-be11-9ed04c6032e8", "ad55f3d7-9016-4a8a-b4d7-ec5b0b4248a5", "3186bd92-91b4-480e-a033-be3c45e9fe76"]
    const user = JSON.parse(localStorage.getItem('user'));


    function handlePressCampaign() {
        const token = localStorage.getItem("token");

        getCreatorCampaigns(token)
            .then(resp => {
                if (resp.status === 200 && resp.data) {
                    setAcceptedCampaigns(resp.data.acceptedCampaigns)
                    setInvitedCampaigns(resp.data.invitedCampaigns)
                    setPublicCampaigns(resp.data.publicCampaigns)

                }
            })
            .catch(err => {
                console.log(err)
            });

        setLeftMenuSelection("campaigns")
    }

    function InvitedCampaigns() {
        if (invitedCampaigns.length > 0) {
            return (
                invitedCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} isInvite={true} campaign={campaign}/>)
                })
            )
        }
    }

    function PublicCampaigns() {
        if (publicCampaigns.length > 0) {
            return (
                publicCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} campaign={campaign}/>)
                })
            )
        }
    }

    function ActiveCampaigns() {
        if (acceptedCampaigns.length > 0) {
            return (
                acceptedCampaigns.map((campaign) => {
                    return (<Campaign isModal={false} campaign={campaign}/>)
                })
            )
        }
    }

    function NoMatch() {
        return (
            <div>
                <h2>Nothing to see here!</h2>
                <p>
                    <Link to="/">Go to the home page</Link>
                </p>
            </div>
        );
    }

    if (user && user.id && dupeBizAccessIds.includes(user.id)) {
        return (
            <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                <View style={styles.background}>
                    <Text style={styles.welcomeText}>Welcome to Dupe Biz</Text>
                    <Pressable style={{flexDirection: 'row', padding: 10}}
                               onPress={() => setLeftMenuSelection("creatorProfile")}>
                        <Image style={{height: 25, width: 25}}
                               src={leftMenuSelection === 'creatorProfile' ? profileSelected : profileUnselected}/>
                        <Text
                            style={leftMenuSelection === 'creatorProfile' ? styles.sideMenuTextSelected : styles.sideMenuText}>Creator
                            Profile</Text>
                    </Pressable>
                    <Pressable style={{flexDirection: 'row', padding: 10}} onPress={handlePressCampaign}>
                        <Image style={{height: 25, width: 25}}
                               src={leftMenuSelection === 'campaigns' ? campaignSelected : campaignUnselected}/>
                        <Text
                            style={leftMenuSelection === 'campaigns' ? styles.sideMenuTextSelected : styles.sideMenuText}>Campaigns</Text>
                    </Pressable>
                </View>

                {leftMenuSelection === 'creatorProfile' && <View style={[styles.background, {minWidth: 700}]}>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={styles.topMenuTextSelected}>Profile Settings</Text>
                    </View>
                    <View style={styles.line}/>
                    <ProfileSettings/>
                </View>}
                {leftMenuSelection === 'campaigns' && <View style={[styles.background, {minWidth: 700}]}>
                    <View style={{flexDirection: 'row'}}>
                        <Pressable onPress={() => setCampaignTabSelected("activeCampaigns")}>
                            <Text
                                style={campaignTabSelected === 'activeCampaigns' ? styles.topMenuTextSelected : styles.topMenuText}>Active
                                Campaigns</Text>
                        </Pressable>
                        <Pressable onPress={() => setCampaignTabSelected("invites")}>
                            <Text
                                style={campaignTabSelected === 'invites' ? styles.topMenuTextSelected : styles.topMenuText}>Invites</Text>
                        </Pressable>
                        <Pressable onPress={() => setCampaignTabSelected("findCampaigns")}>
                            <Text
                                style={campaignTabSelected === 'findCampaigns' ? styles.topMenuTextSelected : styles.topMenuText}>Find
                                Campaigns</Text>
                        </Pressable>
                    </View>
                    <View style={styles.line}/>
                    {campaignTabSelected === 'findCampaigns' && <PublicCampaigns/>}
                    {campaignTabSelected === 'invites' && <InvitedCampaigns/>}
                    {campaignTabSelected === 'activeCampaigns' && <ActiveCampaigns/>}
                </View>}
            </View>
        );
    } else {
        return (<NoMatch/>)
    }
}

const styles = StyleSheet.create({
    text: {
        fontFamily: "'lato', sans-serif",
    },
    boldText: {
        fontWeight: 900,
        fontFamily: "'lato', sans-serif",
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
    },
    subtleMenuText: {
        color: 'grey',
        fontFamily: "'lato', sans-serif",
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5
    },
    background: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        padding: 20,
        minHeight: 600,
    },
    line: {
        display: 'block',
        height: '1px',
        border: 0,
        margin: 10,
        padding: 0,
        backgroundColor: 'lightgrey'
    },
    topMenuTextSelected: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 18,
        padding: 10
    },
    topMenuText: {
        color: '#737373',
        fontFamily: "'forma-djr-display', sans-serif",
        fontSize: 18,
        padding: 10
    },
    welcomeText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 20,
        padding: 10
    },
    campaignCompanyText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        margin: 2
    },
    sideMenuTextSelected: {
        color: '#396DE0',
        fontFamily: "'lato', sans-serif",
        fontWeight: 700,
        fontSize: 18,
        marginLeft: 5
    },
    sideMenuText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        marginLeft: 5
    },
    applyButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 125,
        margin: 5
    },
    applyButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    previewButton: {
        borderRadius: 20,
        backgroundColor: '#B8CBF5',
        minWidth: 125,
        margin: 5
    },
    previewButtonText: {
        color: '#396DE0',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    modal:
        {
            overlay: {
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: 'rgba(57, 109, 224, .5)'
            },
            content: {
                minWidth: 750,
                margin: '20%',
                inset: 0,
                background: 'none',
                flex: 1,
                border: 'none',
                alignSelf: 'center',
                alignContent: 'center'
            }
        },
    dude: {
        color: '#C6CA53',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 35,
        textDecorationLine: 'underline',
        padding: 10,
    },
    applyAcceptText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 50,
        padding: 10,
    },
    boldFormaText: {
        fontWeight: 900,
        fontFamily: "'forma-djr-display', sans-serif",
        padding: 10,
    },
    lato: {
        fontFamily: "'lato', sans-serif",
        padding: 10,
    },
    normalText: {
        padding: 10,
    }

});
export default CreatorBizSignUp;
