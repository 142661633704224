import React, {useEffect, useRef, useState} from 'react'
import {createAestheticSubmission, getAesthetics} from "../services/AestheticService";
import "../stylesheets/AestheticBar.css";
import "../stylesheets/AestheticBar.mobile.css";
import forwardArrow from "../icons/forward-arrow.png";
import backArrow from "../icons/back-arrow.png";
import Modal from "react-modal";
import {getCollectionContent} from "../services/CollectionService";
import {useNavigate} from "react-router-dom";

const AestheticBar = ({searchFunc, selectedAestheticId, aesthetics}) => {

    const [aestheticModal, setAestheticModal] = useState(false);
    const [selectedAesthetic, setSelectedAesthetic] = useState("");
    const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
    const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
    const [aestheticSubmission, setAestheticSubmission] = React.useState("");
    const [aestheticReason, setAestheticReason] = React.useState("");
    let scrl = useRef(null);
    const navigate = useNavigate();

    const style = {
        overlay: {
            backgroundColor: 'rgba(24, 47, 96, 0.80)',
            position: 'fixed',
            zIndex: 999
        }
    };

    function searchAesthetic(aestheticId) {
        setSelectedAesthetic(aestheticId);
        searchFunc(aestheticId);
    }

    //Slide click
    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
        setscrollX(scrollX + shift);

        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    const scrollCheck = () => {
        setscrollX(scrl.current.scrollLeft);
        if (
            Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
            scrl.current.offsetWidth
        ) {
            setscrolEnd(true);
        } else {
            setscrolEnd(false);
        }
    };

    function openModal() {
        setAestheticModal(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setAestheticReason("");
        setAestheticSubmission("");
        setAestheticModal(false);
    }

    function uploadAestheticSubmission() {
        if (aestheticSubmission) {
            const token = localStorage.getItem("token");
            const aesthetic = {
                "aesthetic": aestheticSubmission,
                "description": aestheticReason
            };
            createAestheticSubmission(aesthetic, token)
                .then(result => {
                    closeModal();
                });
        }
    }

    useEffect(() => {
        if (selectedAestheticId) {
            setSelectedAesthetic(selectedAestheticId);
        }
    }, [])

    useEffect(() => {
        if (selectedAestheticId) {
            setSelectedAesthetic(selectedAestheticId);
        }
    }, [selectedAestheticId])
    return(
        <div className={"aesthetic-bar"}>
            <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand aesthetic-a font-bold" href="/">aesthetic</a>
                <div className="navbar navbar-expand aesthetic-div" id="navbarNav">
                    <img onClick={() => slide(-150)} className={"left-aesthetic-arrow-img pointer " + (scrollX !== 0 ? "" : "invisible")} src={backArrow} />
                    <ul className="navbar-nav aesthetic-ul" ref={scrl} onScroll={scrollCheck}>
                        {aesthetics && aesthetics.map((aesthetic) => (
                            <li className={["nav-item", aesthetic.aesthetic === selectedAesthetic ?"active-nav" : ""].join(" ")} onClick={() => searchAesthetic(aesthetic.aesthetic)}>
                                <a className="nav-link aesthetic-text" href="#">{aesthetic.aesthetic}</a>
                            </li>
                        ))}
                    </ul>
                    <img onClick={() => slide(+150)} className={"aesthetic-arrow-img pointer"} src={forwardArrow} />
                </div>
                <div className="submit-aesthetic-div">
                    <p className={"d-inline"}>Don't see your vibe? </p><p onClick={openModal} className={"d-inline pointer blue-color"}><u>Submit an aesthetic</u></p>
                </div>
            </nav>
            <Modal
                isOpen={aestheticModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className={"aesthetic-modal"}
                style={style}
            >
                <div className={"new-aesthetic-header"}>
                    <h3 className={"font-bold d-inline-block blue-color"}>Okay, what are we missing?</h3>
                    <p className={"x-p pointer"} onClick={closeModal}>X</p>
                </div>
                <div className={"aesthetic-submission-input"}>
                    <div className={"relative-div"}>
                        <input id="firstName" className={"grey-input"} value={aestheticSubmission}
                               placeholder={"Submit your aesthetic here"}
                               onChange={(e) => setAestheticSubmission(e.target.value)} maxLength="25"/>
                               <span className={"input-limit-span"}>{25-aestheticSubmission.length}</span>
                    </div>
                    <div className={"relative-div"}>
                        <textarea id="firstName" className={"grey-textarea"} value={aestheticReason} rows="3"
                                  placeholder={"Description"} onChange={(e) => setAestheticReason(e.target.value)}
                                  maxLength="250"/>
                        <span className={"textarea-limit-span"}>{250-aestheticReason.length}</span>
                    </div>
                    <p>We review all aesthetics before they go live, so don't worry if you don't see it right away.</p>
                </div>
                <div className={"create-aesthetic-footer"}>
                    <button className={"blue-btn"} onClick={uploadAestheticSubmission}>Submit</button>
                    <button className={"light-grey-btn"} onClick={closeModal}>Cancel</button>
                </div>
            </Modal>
        </div>
    );
}

export default AestheticBar
