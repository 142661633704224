import axios from "axios";

export async function uploadContent(req, token) {
    const result = await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content', req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function createPortfolio(req, token) {
    const result = await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/portfolio', req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function deleteContent(contentId, token) {
    const result = await axios.delete(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/' + contentId, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function updateContent(req, token) {
    const result = await axios.put(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content', req, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function toggleLike(contentId, token) {
    const result = await axios.post(process.env.REACT_APP_CONTENT_ENDPOINT + '/api/v1/content/' + contentId + "/like", {}, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getContent(userId, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content"
    if (page != null) {
        url = url + "?page=" + page;
    }
    if (userId != null) {
        const result = await axios.get(url, { headers: {'USER-ID': userId}});
        return result;
    } else {
        const result = await axios.get(url);
        return result;
    }
}

export async function getAllContent(userId, page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/all"
    if (page != null) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function searchContent(req, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/search"
    const result = await axios.post(url, req, { headers: {'X-CLIENT-ID': token}});
    return result;
}


export async function getAestheticContent(aestheticId, page, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/aesthetics/" + aestheticId + "/content?page=" + page;
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getAllAestheticContent(aestheticId, page, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/aesthetics/" + aestheticId + "/content/all?page=" + page;
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getUsersContent(userId, token, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content";
    if (page) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getUsersVideoContent(userId, token, page) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/users/" + userId + "/content/video";
    if (page) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getContentSummary(contentId, userId) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/preview";
    const result = await axios.get(url, { headers: {'USER-ID': userId}});
    return result;
}

export async function getDownloadFile(contentId, token) {
    const url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/" + contentId + "/download";
    const config = { responseType: 'blob', headers: {'X-CLIENT-ID': token}};
    const result = await axios.get(url, config);
    return result;
}

export async function getFollowingContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/following/content";
    if (page != null) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getAllFollowingContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/following/content/all";
    if (page != null) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getLikedContent(page, token) {
    let url = process.env.REACT_APP_CONTENT_ENDPOINT + "/api/v1/content/liked";
    if (page != null) {
        url = url + "?page=" + page;
    }
    const result = await axios.get(url, { headers: {'X-CLIENT-ID': token}});
    return result;
}

export async function getS3Content(contentId) {
    let url = "https://d3p3fw3rutb1if.cloudfront.net" + "/photos/"+contentId;
    const result = await axios.get(url, {responseType: "blob"});
    return result;
}
