import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom';
import '../stylesheets/TrendingAestheticPage.css';
import {getTrendingAesthetics} from "../services/AestheticService";


const TrendingAestheticPage = () => {
    const [aesthetics, setAesthetics] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        getTrendingAesthetics()
            .then(resp => {
                if (resp.status == 200 && resp.data) {
                    setAesthetics(resp.data);
                }
            })
    },[]);

    return(
        <div className={"trending-aesthetic-container"}>
            <h3 className={"blue-text font-bold"}>Find Your Vibe</h3>
            <h5 className={"trending-aesthetic-h5 blue-text font-bold"}>Trending Aesthetics</h5>
            {aesthetics && aesthetics.map(aesthetic => (
                <div className={"trending-aesthetic-div pointer"} onClick={event => navigate('/home?aesthetic=' + aesthetic.aesthetic)}>
                    <img src={"https://d3p3fw3rutb1if.cloudfront.net/photos/" + aesthetic.img_preview_id} />
                    <h5 className={"trending-aesthetic-text"}>x&nbsp;&nbsp;{aesthetic.aesthetic}</h5>
                </div>
                ))}
        </div>

    );
}

export default TrendingAestheticPage
