import {Pressable, StyleSheet, Text, TextInput, View} from "react-native-web";
import {Image} from "rebass";
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {acceptCampaign, applyToCampaign, denyCampaign} from "../services/CreatorService";
import {Link} from "react-router-dom";

const Campaign = (props) => {
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
    const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [isApplySuccessModalOpen, setIsApplySuccessModalOpen] = useState(false);
    const [isAcceptSuccessModalOpen, setIsAcceptSuccessModalOpen] = useState(false);
    const [isApplicationPromptOpen, setIsApplicationPromptOpen] = useState(props.isApplicationPromptOpen);
    const [applicationText, setApplicationText] = useState("");
    const [isModal] = useState(props.isModal);
    const [isInvite] = useState(props.isInvite);

    const campaign = props.campaign;
    const [campaignId, setCampaignId] = useState('');
    const [campaignDates, setCampaignDates] = useState('');
    const [campaignName, setCampaignName] = useState('');
    const [campaignDescription, setCampaignDescription] = useState('');
    const [campaignImageUrl, setCampaignImageUrl] = useState('')
    const [compensation, setCompensation] = useState('');
    const [campaignPreview, setCampaignPreview] = useState('');
    const [campaignIndustry, setCampaignIndustry] = useState('            ');
    const [campaignBriefId, setCampaignBriefId] = useState('');

    useEffect(() => {
        if (!campaign) {
            return;
        }

        if (campaign.id) {
            setCampaignId(campaign.id)
        }

        if (campaign.startDate && campaign.EndDate) {
            const monthFormatter = new Intl.DateTimeFormat('default', {month: 'long', day: 'numeric'});
            const startDate = Date.parse(campaign.startDate)
            const endDate = Date.parse(campaign.EndDate)
            setCampaignDates(monthFormatter.format(startDate) + ' - ' + monthFormatter.format(endDate))
        }

        if (campaign.imageId) {
            setCampaignImageUrl("https://d3p3fw3rutb1if.cloudfront.net/business/photos/" + campaign.imageId);
        }

        if (campaign.name) {
            setCampaignName(campaign.name)
        }

        if (campaign.description) {
            setCampaignDescription(campaign.description)
        }

        if (campaign.preview) {
            setCampaignPreview(campaign.preview)
        }

        if (campaign.briefId) {
            setCampaignBriefId(campaign.briefId)
        }


    }, [campaign]);

    function handlePreviewCampaignButtonPress() {
        if (isInvite) {
            setIsInviteModalOpen(true)
        } else {
            setIsSummaryModalOpen(true)
        }
    }

    function handleApplyModalButtonPress() {
        if (!isApplicationPromptOpen) {
            setIsApplicationPromptOpen(true)
        } else {
            const token = localStorage.getItem("token");
            applyToCampaign({creatorNotes: applicationText}, campaignId, token)
                .then(resp => {
                    console.log(resp)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    props.hide()
                    props.onApplySuccess()
                })
        }
    }

    function handleApplyButtonPress() {
        if (!isApplicationPromptOpen) {
            setIsApplicationModalOpen(true)
        } else {
            const token = localStorage.getItem("token");
            applyToCampaign({applicationText: applicationText}, campaignId, token)
                .then(resp => {
                    console.log(resp)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    props.hide()
                    props.onApplySuccess()
                })
        }
    }

    function handleAcceptModalButtonPress() {
        const token = localStorage.getItem("token");
        acceptCampaign(campaignId, token)
            .then(resp => {
                console.log(resp)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                props.hide()
                props.onAcceptSuccess()
            })
    }

    function handleDenyModalButtonPress() {
        const token = localStorage.getItem("token");
        denyCampaign(campaignId, token)
            .then(resp => {
                console.log(resp)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                props.hide()
            })
    }

    function AcceptCampaignModal() {
        return (<View style={[styles.background, {minHeight: 100}]}>
            <Text style={styles.dude}>DUDE!</Text>
            <Text style={styles.applyAcceptText}>You've accepted this campaign!</Text>
            <Text style={styles.boldFormaText}>You can now find this campaign in your "active campaigns" tab.</Text>
            <Text style={styles.normalText}>Keep an eye on your email. The brand will reach out to you with more details
                regarding this campaign.</Text>
            <Pressable style={[styles.applyButton, {maxWidth: 150, alignSelf: 'flex-end'}]}
                       onPress={() => setIsAcceptSuccessModalOpen(false)}>
                <Text style={styles.applyButtonText}>See more campaigns</Text>
            </Pressable>
        </View>)
    }

    function ApplyCampaignModal() {
        return (<View style={[styles.background, {minHeight: 100}]}>
            <Text style={styles.dude}>DUDE!</Text>
            <Text style={styles.applyAcceptText}>You‘ve applied to this campaign!</Text>
            <Text style={styles.boldFormaText}>You’ll receive an email if the brand accepts your application.</Text>
            <Text style={styles.normalText}>Once the brand has accepted your application, the campaign will show up in
                your “active campaigns”, but keep an eye on your email to see if you’ve been accepted.</Text>
            <Pressable style={[styles.applyButton, {maxWidth: 150, alignSelf: 'flex-end'}]}
                       onPress={() => setIsApplySuccessModalOpen(false)}>
                <Text style={styles.applyButtonText}>See more campaigns</Text>
            </Pressable>
        </View>)
    }


    return (
        <View style={[styles.background, {minHeight: 100}]}>
            <View style={{flexDirection: 'row'}}>
                <View>
                    <Image style={{borderRadius: 5, marginRight: 10}} height={125} width={125}
                           src={campaignImageUrl}>

                    </Image>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', flex: 1}}>
                    <View>
                        <View style={{justifyContent: 'space-between', flex: 1}}>
                            <View>
                                <Text style={styles.campaignCompanyText}>{campaignName}</Text>
                                <Text style={styles.campaignNameText}>{campaignDescription}</Text>
                            </View>
                            <View>
                                <View style={{flexDirection: 'row', margin: 2}}>
                                    <Text style={[styles.subtleText, {fontSize: 12}]}>$0 and Free Product</Text>
                                </View>
                                <View style={{flexDirection: 'row', margin: 2}}>
                                    <Text style={[styles.subtleText, {fontSize: 12}]}>{campaignDates}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    {!isModal && <View>
                        <Text style={[styles.subtleText, {
                            alignSelf: 'flex-end',
                            margin: 5,
                            fontSize: 11
                        }]}>{campaignIndustry}</Text>
                        <Pressable style={styles.previewButton} onPress={handlePreviewCampaignButtonPress}>
                            <Text style={styles.previewButtonText}>Preview Campaign</Text>
                        </Pressable>
                        {!isInvite &&
                            <Pressable style={styles.applyButton} onPress={handleApplyButtonPress}>
                                <Text style={styles.applyButtonText}>Apply</Text>
                            </Pressable>
                        }
                        {isInvite &&
                            <View style={{flexDirection: 'row'}}>
                                <Pressable style={[styles.applyButton, {minWidth: 75}]}
                                           onPress={handleAcceptModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Accept</Text>
                                </Pressable>
                                <Pressable onPress={handleDenyModalButtonPress}
                                           style={[styles.applyButton, {backgroundColor: '#A6A6A6', minWidth: 75}]}>
                                    <Text style={styles.applyButtonText}>Deny</Text>
                                </Pressable>
                            </View>
                        }
                    </View>}
                </View>
            </View>

            <View>
                {isModal && !isApplicationPromptOpen &&
                    <View style={styles.background}>
                        <Text style={styles.campaignCompanyText}>About this Campaign</Text>
                        <Text style={styles.sideMenuText}>{campaignPreview}</Text>
                    </View>
                }

                {isModal && isApplicationPromptOpen &&
                    <View style={{paddingTop: 10}}>
                        <Text style={styles.boldText}>Give a few sentences why you would be a good fit for this
                            campaign</Text>
                        <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                            <TextInput
                                multiline
                                rows={6}
                                maxLength={500}
                                onChangeText={text => setApplicationText(text)}
                                value={applicationText}
                                style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                            />
                            <Text style={[styles.subtleText, {alignSelf: 'flex-end'}]}>
                                ({applicationText.length}/500)
                            </Text>
                        </View>
                    </View>

                }

                {isModal &&
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingTop: 10,
                        alignItems: 'baseline'
                    }}>
                        {campaignBriefId !== null && campaignBriefId !== '' && <View>
                            <Pressable style={{minWidth: 125}}>
                                <Link to={"https://d3p3fw3rutb1if.cloudfront.net/business/attachments/" + campaignBriefId} target="_blank">
                                    <Text style={[styles.previewButtonText, {
                                        textDecorationLine: 'underline',
                                        padding: 0
                                    }]}>View
                                        Campaign Brief
                                    </Text>
                                </Link>
                            </Pressable>
                        </View>}
                        {!isInvite &&
                            <View>
                                <Pressable style={[styles.applyButton, {minWidth: 125, margin: 0}]}
                                           onPress={handleApplyModalButtonPress}>
                                    <Text style={styles.applyButtonText}>
                                        Apply
                                    </Text>
                                </Pressable>
                            </View>
                        }
                        {isInvite &&
                            <View style={{flexDirection: 'row'}}>
                                <Pressable style={[styles.applyButton, {minWidth: 75}]}
                                           onPress={handleAcceptModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Accept</Text>
                                </Pressable>
                                <Pressable style={[styles.applyButton, {backgroundColor: '#A6A6A6', minWidth: 75}]}
                                           onPress={handleDenyModalButtonPress}>
                                    <Text style={styles.applyButtonText}>Deny</Text>
                                </Pressable>
                            </View>
                        }
                    </View>
                }
            </View>

            <Modal
                style={styles.modal}
                isOpen={isSummaryModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsSummaryModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={false}
                    hide={() => setIsSummaryModalOpen(false)}
                    onApplySuccess={() => setIsApplySuccessModalOpen(true)}
                    campaign={props.campaign}
                />
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isApplicationModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsApplicationModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={true}
                    hide={() => setIsApplicationModalOpen(false)}
                    onApplySuccess={() => setIsApplySuccessModalOpen(true)}
                    campaign={props.campaign}
                />
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isInviteModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsInviteModalOpen(false)}>
                <Campaign
                    isModal={true}
                    isApplicationPromptOpen={false}
                    isInvite={true}
                    hide={() => setIsInviteModalOpen(false)}
                    onAcceptSuccess={() => setIsAcceptSuccessModalOpen(true)}
                    campaign={props.campaign}
                />
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isApplySuccessModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsApplySuccessModalOpen(false)}>
                <ApplyCampaignModal/>
            </Modal>
            <Modal
                style={styles.modal}
                isOpen={isAcceptSuccessModalOpen}
                appElement={document.getElementById('root')}
                onRequestClose={() => setIsAcceptSuccessModalOpen(false)}>
                <AcceptCampaignModal/>
            </Modal>

        </View>
    )
}

const styles = StyleSheet.create({
    background: {
        backgroundColor: 'white',
        borderRadius: 20,
        border: '1px solid rgba(57, 109, 224, .3)',
        margin: 20,
        padding: 20,
        minHeight: 350,
    },
    applyButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 125,
        margin: 5
    },
    applyButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    sideMenuText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        padding: 10
    },
    campaignCompanyText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 900,
        fontSize: 24,
        margin: 2
    },
    campaignNameText: {
        color: '#737373',
        fontFamily: "'lato', sans-serif",
        fontSize: 18,
        fontWeight: 500,
        margin: 2
    },
    previewButton: {
        borderRadius: 20,
        backgroundColor: '#B8CBF5',
        minWidth: 125,
        margin: 5
    },
    previewButtonText: {
        color: '#396DE0',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
    },
    dude: {
        color: '#C6CA53',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 35,
        textDecorationLine: 'underline',
        padding: 10,
    },
    applyAcceptText: {
        color: '#396DE0',
        fontFamily: "'forma-djr-display', sans-serif",
        fontWeight: 700,
        fontSize: 50,
        padding: 10,
    },
    boldFormaText: {
        fontWeight: 900,
        fontFamily: "'forma-djr-display', sans-serif",
        padding: 10,
    },
    lato: {
        fontFamily: "'lato', sans-serif",
        padding: 10,
    },
    normalText: {
        padding: 10,
    },
    boldText: {
        fontWeight: 900,
        color: '#737373',
        fontFamily: "'lato', sans-serif",
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    modal:
        {
            overlay: {
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                backgroundColor: 'rgba(57, 109, 224, .5)'
            },
            content: {
                minWidth: 750,
                margin: '20%',
                inset: 0,
                background: 'none',
                flex: 1,
                border: 'none',
                alignSelf: 'center',
                alignContent: 'center'
            }
        },
})

export default Campaign