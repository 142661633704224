import React, {useEffect, useState} from "react";
import {CheckBox, Pressable, StyleSheet, Text, TextInput, View} from "react-native-web";
import {useNavigate} from "react-router-dom";
import {getCreatorProfile, saveCreator} from "../services/CreatorService";

const ProfileSettings = () => {
    const navigate = useNavigate();
    const [isFeatureMeSelected, setFeatureMeSelected] = useState(false);
    const [isPhotoSelected, setPhotoSelected] = useState(false);
    const [isVideoSelected, setVideoSelected] = useState(false);
    const [isProductSelected, setProductSelected] = useState(false);
    const [isCommissionSelected, setCommissionSelected] = useState(false);
    const [isPaymentSelected, setPaymentSelected] = useState(false);
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [bio, setBio] = useState('');
    const [featureMeClicked, setFeatureMeClicked] = useState(false);

    const countryOptions = ['United States of America', 'Canada'];
    const stateOptionsUS = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    const stateOptionsCAN = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'];

    function CountryOptions() {
        return (
            countryOptions.map((country) => {
                return (<option key={country} value={country}>{country}</option>)
            })
        )
    }

    function StateOptions() {
        if (country === 'United States of America') {
            return (
                stateOptionsUS.map((state) => {
                    return (<option key={state} value={state}>{state}</option>)
                })
            )
        } else if (country === 'Canada') {
            return (
                stateOptionsCAN.map((state) => {
                    return (<option key={state} value={state}>{state}</option>)
                })
            )
        }
    }

    function handleFeatureMeClicked() {
        setFeatureMeSelected(!isFeatureMeSelected)
        setFeatureMeClicked(true)
    }

    function handleUpdate() {
        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const request = {
            creatorId: storedUser.id,
            available: isFeatureMeSelected,
            productCompensation: isProductSelected,
            commissionCompensation: isCommissionSelected,
            paymentCompensation: isPaymentSelected,
            country: country,
            provenance: state,
            bio: bio
        };

        saveCreator(request, token)
            .then(resp => {
                console.log(resp)
            }).catch(err => {
            console.error(err)
        })
    }

    //On page load
    useEffect(() => {
        let active = true;
        const token = localStorage.getItem("token");

        getCreatorProfile(token)
            .then(resp => {
                if (active && resp.status === 200 && resp.data) {
                    setFeatureMeSelected(resp.data.available)
                    setProductSelected(resp.data.productCompensation)
                    setCommissionSelected(resp.data.commissionCompensation)
                    setPaymentSelected(resp.data.paymentCompensation)
                    setBio(resp.data.bio)
                    setCountry(resp.data.country)
                    setState(resp.data.provenance)
                }
            })
            .catch(err => {
                if (active && err.response.status === 401) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    navigate('/home');
                } else if (active && err.response.status === 400) {
                    console.log("No creator profile found")
                }
            })

        return () => {
            active = false;
        };
    }, [navigate]);

    //On change feature me
    useEffect(() => {
        if (!featureMeClicked) {
            return;
        }
        const token = localStorage.getItem("token");
        const storedUser = JSON.parse(localStorage.getItem("user"));

        const request = {
            creatorId: storedUser.id,
            available: isFeatureMeSelected,
            productCompensation: isProductSelected,
            commissionCompensation: isCommissionSelected,
            paymentCompensation: isPaymentSelected,
            country: country,
            provenance: state,
            bio: bio
        };

        saveCreator(request, token)
            .then(resp => {
                console.log(resp)
            }).catch(err => {
            console.error(err)
        }).finally(() => {
            setFeatureMeClicked(false)
        })

    }, [featureMeClicked]);

    return (<View>
        <View style={{margin: 10}}>
            <Text style={styles.boldText}>Are you interested in connecting with brands for freelance content
                creation?</Text>
            <View style={styles.checkBoxContainer}>
                <View style={styles.checkBox}>
                    {/*<ToggleSlider active={isFeatureMeSelected} onToggle={handleFeatureMeClicked}*/}
                    {/*              barBackgroundColorActive={'#396DE0'} barHeight={20} barWidth={40} handleSize={14}*/}
                    {/*              padding={3}/>*/}
                    <CheckBox
                        color={'#396DE0'}
                        value={isFeatureMeSelected}
                        onValueChange={handleFeatureMeClicked}
                    />
                    <Text style={styles.text}> Yes, feature my Dupe profile on the Dupe Biz hiring page so brands
                        can find me</Text>
                </View>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Text style={styles.subtleText}>Requests will be sent to your email</Text>
                <View style={{flexDirection: 'row'}}>
                    <Text style={styles.subtleText}>by clicking yes, you are agreeing to our </Text>
                    <Text onClick={() => navigate('/license-and-terms')}
                          style={[styles.subtleText, {textDecorationLine: 'underline', cursor: 'pointer'}]}>Terms of
                        Service</Text>
                </View>
            </View>
        </View>
        {isFeatureMeSelected && <View>
            <View style={{flexDirection: 'row'}}>
                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of content do you shoot?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CheckBox
                                    color={'#396DE0'}
                                    value={isPhotoSelected}
                                    onValueChange={setPhotoSelected}
                                />
                                <Text style={styles.text}> Photo</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CheckBox
                                    color={'#396DE0'}
                                    value={isVideoSelected}
                                    onValueChange={setVideoSelected}
                                />
                                <Text style={styles.text}> Video</Text>
                            </View>
                        </View>
                    </View>

                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What Country are you based in?</Text>
                        <select value={country} className={"Dropdown-root"} placeholder={"Select..."}
                                onChange={e => setCountry(e.target.value)}>
                            <option value={""} disabled/>
                            <CountryOptions/>
                        </select>
                    </View>
                </View>

                <View style={{flexDirection: 'column'}}>
                    <View style={{margin: 10}}>
                        <Text style={styles.boldText}>What type of compensation are you looking for?</Text>
                        <View style={styles.checkBoxContainer}>
                            <View style={styles.checkBox}>
                                <CheckBox
                                    color={'#396DE0'}
                                    value={isProductSelected}
                                    onValueChange={setProductSelected}
                                />
                                <Text style={styles.text}> Product</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CheckBox
                                    color={'#396DE0'}
                                    value={isCommissionSelected}
                                    onValueChange={setCommissionSelected}
                                />
                                <Text style={styles.text}> Commission</Text>
                            </View>
                            <View style={styles.checkBox}>
                                <CheckBox
                                    color={'#396DE0'}
                                    value={isPaymentSelected}
                                    onValueChange={setPaymentSelected}
                                />
                                <Text style={styles.text}> Payment</Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <View style={{margin: 10, maxWidth: 200}}>
                            <Text style={styles.boldText}>State</Text>
                            <select value={state} className={"Dropdown-root"} placeholder={"Select..."}
                                    onChange={e => setState(e.target.value)}>
                                <option value={""} disabled/>
                                <StateOptions/>
                            </select>
                        </View>
                    </View>
                </View>
            </View>

            <View style={{margin: 10}}>
                <Text style={styles.boldText}>Bio</Text>
                <View style={[styles.checkBoxContainer, {flexDirection: 'column'}]}>
                    <TextInput
                        multiline
                        rows={6}
                        maxLength={500}
                        onChangeText={text => setBio(text)}
                        value={bio}
                        style={{padding: 10, display: 'flex', color: 'black', fontFamily: "'lato', sans-serif"}}
                    />
                    <Text style={[styles.subtleText, {alignSelf: 'flex-end'}]}>
                        ({bio.length}/500)
                    </Text>
                </View>
                <Text style={styles.subtleText}>This is a great place to talk about what type of content you shoot,
                    your style, etc</Text>
            </View>
            <View>
                <Pressable style={[styles.applyButton, {minWidth: 100, alignSelf: 'flex-end'}]} onPress={handleUpdate}>
                    <Text style={styles.applyButtonText}>Update</Text>
                </Pressable>
            </View>
        </View>}
    </View>)
}

const styles = StyleSheet.create({
    boldText: {
        fontWeight: 900,
        fontFamily: "'lato', sans-serif",
    },
    subtleText: {
        color: '#A6A6A6',
        fontFamily: "'lato', sans-serif",
    },
    checkBoxContainer: {
        padding: 5,
        flexDirection: 'row',
        backgroundColor: '#F1F1F1',
        borderRadius: 5,
        border: '1px solid #396DE0'
    },
    checkBox: {
        flexDirection: 'row',
        margin: 5
    },
    applyButton: {
        borderRadius: 20,
        backgroundColor: '#396DE0',
        minWidth: 125,
        margin: 5
    },
    applyButtonText: {
        color: 'white',
        fontWeight: 700,
        fontFamily: "'lato', sans-serif",
        alignSelf: 'center',
        fontSize: 14,
        padding: 10,
    },
    text: {
        fontFamily: "'lato', sans-serif",
    },
})

export default ProfileSettings