import axios from "axios";

export async function signUp(token, user) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users', user, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getProfile(token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/profile', { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getUsernameProfile(username, token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/profile/' + username, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function updateUser(user, token) {
    const result = await axios.put(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users', user, { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getUser(userId) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + userId)
    return result;
}


export async function checkUsername(username, token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + username + '/check', { headers: {'X-CLIENT-ID': token}})
    return result;
}
export async function followUser(userId, token) {
    const result = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + userId + '/follow', {} , { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function unfollowUser(userId, token) {
    const result = await axios.delete(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + userId + '/follow', { headers: {'X-CLIENT-ID': token}})
    return result;
}

export async function getUsersFollowings(username, token) {
    const result = await axios.get(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/v1/users/' + username + '/following', { headers: {'X-CLIENT-ID': token}})
    return result;
}
